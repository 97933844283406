import React from "react";
import { useLocation } from "react-router-dom";
import ReactWeather, { useVisualCrossing } from "react-open-weather";

export default function App() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const lat = searchParams.get("lat");
  const lon = searchParams.get("lon");

  const { data, isLoading, errorMessage } = useVisualCrossing({
    key: "QTRAZZNV9GSZG59EJW9C5NAZT",
    lat: lat,
    lon: lon,
    lang: "en",
    unit: "us", // values are (metric,us,uk)
  });

  return (
    <ReactWeather
      isLoading={isLoading}
      errorMessage={errorMessage}
      data={data}
      lang="en"
      unitsLabels={{ temperature: "F" }}
      showForecast={false}
    />
  );
}
